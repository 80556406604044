.topDot {
  animation: topDotAnimation 1.5s infinite alternate;
}

.topDotHorizontalBar {
  animation: topDotHorizontalBarAnimation 1.5s infinite alternate;
}

.middleDot {
  animation: middleDotAnimation 1.5s infinite alternate;
}

.middleDotHorizontalBar {
  animation: middleDotHorizontalBarAnimation 1.5s infinite alternate;
}

.middleDotVerticalbar {
  animation: middleDotVerticalBarAnimation 1.5s infinite alternate;
}

@keyframes topDotAnimation {
  0% {
    transform: translateX(-20px);
  }
  49% {
    transform: translateX(-20px);
  }
  50% {
    width: 10px;
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes topDotHorizontalBarAnimation {
  0% {
    width: 10px;
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    width: 10px;
    opacity: 1;
  }
  100% {
    width: 30px;
  }
}

@keyframes middleDotAnimation {
  0% {
    transform: translate(-15px, 15px);
  }
  50% {
    transform: translate(-15px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes middleDotHorizontalBarAnimation {
  0% {
    width: 10px;
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    width: 10px;
    opacity: 1;
  }
  100% {
    width: 25px;
  }
}

@keyframes middleDotVerticalBarAnimation {
  0% {
    height: 10px;
    y: 31px;
  }
  50% {
    height: 25px;
    y: 16px;
  }
}
